import { fields } from '@/modules/plot/mock/fieldSeeds'
import {
  createSowing,
  oldSowings,
} from '@/modules/oldSowing/mock/oldSowingSeeds.js'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

// CM: deletar após conclusão do projeto
const oldSowingRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/semeaduras',
      result: ({ queryParams }) => {
        const currentFarmFieldIds = fields.reduce((acc, field) => {
          if (
            field.fazenda_id == queryParams.fazenda_id &&
            field.enabled &&
            !field.deleted_at
          ) {
            acc.push(field.id)
          }
          return acc
        }, [])
        let filteredSowings = oldSowings
        filteredSowings = oldSowings.filter(
          (sowing) =>
            sowing.plot_enabled &&
            !sowing.deleted &&
            currentFarmFieldIds.includes(sowing.talhao_id)
        )
        return APIFailureWrapper({
          content: filteredSowings,
          errorMessage: 'Erro na listagem de semeaduras',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/semeaduras',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        createSowing(responseObj)
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação de semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/semeaduras/:sowing_id',
      result: ({ params }) => {
        oldSowings.forEach((sowing) => {
          if (sowing.id == params.sowing_id) sowing.deleted = true
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção da semeadura',
        })
      },
    },
    'on'
  ),
]

export { oldSowingRoutes }
